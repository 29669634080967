import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Grid, IconButton, Paper, Typography } from '@mui/material';
import CardCover from './CardCover';
import TestListComponent from './TestListComponent';
import { styled } from '@mui/material/styles';
import Bottom from './BottomNav';
import { blue } from '@mui/material/colors';
import { theme } from '../theme'


const Refert = () =>
{
    const navigate = useNavigate();
    const location = useLocation();
    const { elementName, testData, rtfContent, labData, allLabs, cardDate } = location.state || {};
    const { testDate, testId } = location.state || {};

    const DemoPaper = styled(Paper)(({ theme }) => ({
        width: 120,
        height: 120,
        padding: theme.spacing(2),
        ...theme.typography.body2,
        textAlign: 'center',
    }));
    const onBack = () =>
    {
        console.log(testData)

        navigate(-1); // Navigates back to the previous page

    };
    console.log(rtfContent)



    return (
        <Box flex={2} p={0} >
            <CardCover
                testDate={testDate}
                testId={elementName}
                title="Refert Mjekesor"
                imageUrl="https://www.usnews.com/object/image/00000184-5d22-d253-a784-dda799cf0000/gettyimages-1390026192.jpg?update-time=1668009859280&size=responsive970"
                icon={<IconButton onClick={onBack} sx={{ color: 'white' }} ><ArrowBackIos /></IconButton>}
            />
            <Card sx={{
                marginLeft: 'auto',
                marginRight: 'auto', paddingTop: 0, paddingBottom: 0, marginTop: 20, width: "90%", backgroundColor: 'white', borderRadius: " 10px "
            }} >
                <CardContent>

                    <Typography variant='button' color={theme.palette.primary.main}>Refert</Typography>
                    <div dangerouslySetInnerHTML={{ __html: rtfContent }} ></div>



                </CardContent>
            </Card>

            <Bottom />
        </Box>
    );
};

export default Refert;
