import { Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Divider, Grid, ThemeProvider, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import CardComp from './CardComp'
import CardCover from './CardCover'
import Bottom from './BottomNav'
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from "react-router-dom"
import { Switch } from 'react-router-dom';
import { blue } from '@mui/material/colors'
import { theme } from '../theme'
import { Calculate, CalendarMonthOutlined, LocalHospital, Note, PersonSharp, Science } from '@mui/icons-material'
import Contact from "./Contact"
import CardWithBackground from './CardWithBackground'
import { AuthContext } from './AuthContext'


const Home = () =>
{
    const formatDate = (date) =>
    {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(date).toLocaleDateString('en-GB', options);
    };
    const [latestEventData, setLatestEventData] = useState(null);
    const { authState: { idPat, token, patName, patSurname, typeOfCard }, checkTokenExpiration } = useContext(AuthContext);

    const navigate = useNavigate();
    const location = useLocation();
    const [eventData, setEventData] = useState(null);
    useEffect(() =>
    {

        const fetchData = async () =>
        {
            try
            {
                await checkTokenExpiration(new Date().getTime());
                if (!idPat)
                {
                    // idPat is not available yet, return or handle accordingly
                    return;
                }
                if (!token)
                {
                    // idPat is not available yet, return or handle accordingly
                    return;
                }

                const response = await fetch(`${process.env.REACT_APP_INSALUS_EVENT_PATID_ENDPOINT}=${idPat}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`, // Add your authorization header
                    },
                });

                if (response.ok)
                {
                    const data = await response.json();
                    setEventData(data);
                    if (data.length > 0)
                    {
                        // Sort the events based on the date property in descending order
                        data.sort((a, b) => new Date(b.eventDate) - new Date(a.eventDate));
                        // Set the latest event data

                        var currentDate = new Date();
                       
                        if (data[0].eventDate >= currentDate)
                        {
                            setLatestEventData(data[0]);
                        }

                    }
                } else
                {
                  
                }
            } catch (error)
            {
               
            }
        };

        // Call the fetchData function
        fetchData();
    }, [idPat, token]);


    const handleGoBack = () =>
    {
        // Use navigate to go back in the history
        navigate(-1);
    };



    const handleCalendarClick = () =>
    {


        navigate("/calendar", {
            state: {
                eventData: eventData

            }
        });
    };
    return (
        <ThemeProvider theme={theme}>

            <Box width={"100%"} marginTop={0} display={'flex'} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} >

                <Card sx={{ padding: 0, marginTop: 0, marginBottom: 3, width: "100%", backgroundColor: theme.palette.primary.main, borderRadius: " 15px ", borderTopRightRadius: 0, borderTopLeftRadius: 0 }}  >
                    <CardContent sx={{ color: theme.palette.otherColor.main }}>
                        <Grid container spacing={2} alignItems="center" >
                            <Grid item>
                                <PersonSharp sx={{ width: 60, height: 60, fontSize: 32 }}></PersonSharp>
                            </Grid>
                            <Grid item>
                                <Typography variant="h4"> Miresevini , <br></br>{patName} {patSurname}</Typography>

                            </Grid>
                        </Grid>

                        <Typography variant="body1">
                            <strong>Pacienti:</strong> {idPat}
                        </Typography>
                        <Typography variant="body1">
                            <strong>inSalus:</strong> {typeOfCard}
                        </Typography>
                        <Contact />
                    </CardContent>
                </Card>

                <Card onClick={handleCalendarClick} sx={{ paddingTop: 0, paddingBottom: 0, marginTop: 3, marginBottom: 3, marginLeft: 3, marginRight: 3, width: "100%", backgroundColor: 'white', borderRadius: " 10px " }} >
                    <CardContent sx={{ color: theme.palette.primary.main }}>
                        <Grid container spacing={2} alignItems="center" >
                            <Grid item>
                                <CalendarMonthOutlined sx={{ width: 40, height: 40, fontSize: 32 }}></CalendarMonthOutlined>
                            </Grid>
                            <Grid item>
                                <Typography sx={{ fontSize: 24 }}><strong>Kalendari i vizitave tuaja</strong></Typography>
                                {latestEventData && (
                                    <>
                                        <Typography variant="body0" sx={{ fontSize: 14.9 }}>Vizita e rradhes ne daten {formatDate(latestEventData.eventDate)}</Typography>
                                        <Typography variant="body1" sx={{ fontSize: 14.9 }}><strong>{latestEventData.eventDoctor}</strong></Typography>
                                    </>
                                )}
                                {!latestEventData && (
                                    <>
                                        <Typography variant="body1" sx={{ fontSize: 14.9 }}>Asnje vizite e regjistruar.</Typography>

                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <CardWithBackground imageUrl="https://medicalmirror.org/wp-content/uploads/2020/09/image-55.jpg" title="ANALIZAT" icon={<Science sx={{ width: 60, height: 60, fontSize: 32 }}></Science>} theme={theme} route="/analiza" idPat={idPat} token={token} />
                <CardWithBackground imageUrl="https://www.usnews.com/object/image/00000184-5d22-d253-a784-dda799cf0000/gettyimages-1390026192.jpg?update-time=1668009859280&size=responsive970" title="VIZITA MJEKESORE" icon={<LocalHospital sx={{ width: 60, height: 60, fontSize: 32 }}></LocalHospital>} theme={theme} route="/vizita" idPat={idPat} token={token} />
            </Box>
        </ThemeProvider>



    )
}
export default Home