import { BottomNavigation, Box, IconButton } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import
{
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    Legend,
    Area,
    AreaChart,
    ComposedChart,
} from 'recharts';
import Bottom from './BottomNav';
import CardCover from './CardCover';
import { ArrowBack } from '@mui/icons-material';
import TestComponent from './TestComponent';
import TestListComponent from './TestListComponent';

const ElementDetails = () =>
{
    const location = useLocation();
    const { result, unit, rangeMin, rangeMax, elementId, element, allLabs } = location.state || {};

    const elements = [];


    const flattenLabData = () =>
    {
        const combinedRangeData = [];

        allLabs.forEach((lab) =>
        {
            const labDate = new Date(lab.date); // Convert date to a Date object

            lab.tests.forEach((test) =>
            {
                const testDescription = test.testDescription;

                test.elements.forEach((element1) =>
                {
                    if (elementId && element1.elementId && elementId === element1.elementId)
                    {


                        const Min = element1.rangeMin
                        const Max = element1.rangeMax
                        elements.push({
                            elementName: labDate.toLocaleDateString('en-US'),
                            result: element1.result,
                            rangeMin: element1.rangeMin,
                            rangeMax: element1.rangeMax,
                            indicator: element1.indicator,
                            unit: element1.unit,


                        })
                        combinedRangeData.push({
                            date: labDate.toLocaleDateString('en-US'), // Format date as short
                            testDescription: testDescription,
                            elementName: element1.elementName,
                            result: parseFloat(element1.result),
                            Min,
                            Max,
                            areaBetween: { Max, Min },// Assuming the result is a number
                        });

                        // Add upper and lower range data

                    }
                });
            });
        });


       
        return { combinedRangeData };
    };
    const navigate = useNavigate();
    const onBack = () =>
    {

        navigate(-1); // Navigates back to the previous page
    };
    const testData = [{
        testDescription: "Historiku",
        elements: elements

    }];
    const MyLabLineChart = () =>
    {
        const { combinedRangeData } = flattenLabData();

        return (
            <ResponsiveContainer width="85%" height={250}>
                <ComposedChart data={combinedRangeData} margin={{ top: 40, right: 0, left: 0, bottom: 0 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />

                    <Area
                        type="monotone"
                        dataKey="Max"
                        // Line color for the area
                        fill="#8884d8"
                        fillOpacity={0.1}
                        baseLine={combinedRangeData.lowerRangeData}
                    // Fill color for the area
                    />

                    <Area
                        type="monotone"
                        dataKey="Min"
                        // Line color for the area

                        fill="white"
                    // Fill color for the area
                    />

                    <Line
                        type="monotone"
                        dataKey="result"
                        stroke="#61dafb"
                        strokeWidth={3}
                        dot={{ stroke: '#61dafb', strokeWidth: 5, r: 6, fill: '#fff' }}
                        activeDot={{ r: 8, strokeWidth: 3, fill: '#61dafb' }}
                    />

                </ComposedChart>
            </ResponsiveContainer>
        );
    };

    return (
        <Box>
            <CardCover
                title={element}
                imageUrl="https://medicalmirror.org/wp-content/uploads/2020/09/image-55.jpg"
                icon={<IconButton onClick={onBack} sx={{ color: 'white' }} ><ArrowBack /></IconButton>}

            />
            <MyLabLineChart />
            <TestListComponent clickBool={false} marginTop={0} testdata={testData} />
            <Bottom />
        </Box>
    );
};

export default ElementDetails;
