import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { theme } from "./theme"
import { ThemeProvider } from '@emotion/react';
import { AuthProvider } from 'react-auth-kit'; // Correct import statement
import { BrowserRouter } from 'react-router-dom';
import './style.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <div
      style={{
        backgroundColor: theme.palette.otherColor.main,
        height: '100vh',
       
      }}
    >

      <App />


    </div>
  </ThemeProvider>
);
