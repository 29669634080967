// LoadingCard.jsx
import React from 'react';
import { Card, CardContent, Divider, Grid, Skeleton, Typography } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';

const CardSceleton = () =>
{
    return (

        <Card sx={{ position: 'relative', margin: 2 }}>
            <Skeleton variant="rectangular" width="100%" height={100} />

            <CardContent sx={{ color: 'gray' }}>
                <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Skeleton variant="text" width={30} height={20} />
                        <Skeleton variant="text" width={30} height={12} />
                        <Skeleton variant="text" width={30} height={12} />
                    </Grid>
                    <Grid item xs={7} md={6}>
                        <Typography
                            gutterBottom
                            variant="h6"
                            component="div"
                            color="#6a7885"
                        >
                            <Skeleton variant="text" width="70%" />
                        </Typography>
                        <Typography variant="body1" color="#ee6c4d">
                            <Skeleton variant="text" width="50%" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ArrowForwardIos />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>

    );
};

export default CardSceleton;
