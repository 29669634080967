// AnalizaLoadingSkeleton.jsx
import React from 'react';
import { Box, Skeleton } from '@mui/material';
import CardSceleton from './CardSceleton';
import CardCoverSceleton from './CardCoverSceleton';

const DataLoadingSkeleton = () =>
{
    return (
        <Box flex={4} p={0}>
            <CardCoverSceleton />
            <CardSceleton />
            <CardSceleton />
            <CardSceleton />
            <CardSceleton />

        </Box>



    );
};

export default DataLoadingSkeleton;
