import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: "#428bf9",
            light: "#b7cbe9"
        },
        secondary: {
            main: "#70afff",
            light: "#a1c5fc"
        },
        otherColor: {
            main: "#f3f7fd"
        }
    }
})