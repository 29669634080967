import { Box, Button, CardActions, CardContent, CardMedia, ThemeProvider, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Vizita from './vizita'
import Analiza from './Analiza'
import Home from "./Home"
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom"
import { Switch } from 'react-router-dom';
import { theme } from '../theme'
import Elements from './Elements'
import ElementDetails from './ElementDetails'
import Refert from './Refert'
import Authent from './Authent'
import AuthWrapper from './AuthWrapper'
import Calendar from './Calendar'





const Feed = () =>
{
    const [cardTittle, setCardTittle] = useState(null)
    const [cardTest, setCardTest] = useState(null)
    const [cardName, setCardName] = useState(null)

    return (
        <ThemeProvider theme={theme}>
            <Box flex={6}>
                <Router>
                    <AuthWrapper>
                        <Routes>
                            <Route path="/:uuid" element={<Authent />} />
                            <Route path="/calendar" element={<Calendar />} />

                            <Route path='/vizita' element={<Vizita />} />


                            <Route path='/analiza' element={<Analiza />} />



                            <Route path='/Home' element={<Home />} />


                            <Route path='/analiza/:testid' element={<Elements />} />


                            <Route path='/vizita/:testid' element={<Refert />} />


                            <Route path='/analiza/:testid/element/:elementId' element={<ElementDetails />} />
                        </Routes>
                    </AuthWrapper>
                </Router>
            </Box>
        </ThemeProvider>
    )
}
export default Feed