import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { CalendarMonthSharp, Home, LocalHospital, Science } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { Badge } from '@mui/material';
import { CalendarIcon } from '@mui/x-date-pickers';

export default function FixedBottomNavigation({ })
{
    const location = useLocation();
    const [value, setValue] = React.useState(getValueFromLocation(location));

    const handleChange = (event, newValue) =>
    {
        setValue(newValue);

    };

    return (
        <Box sx={{ pb: 7 }}>
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation showLabels value={value} onChange={handleChange}>
                    <BottomNavigationAction label="Home" icon={<Home />} component={Link} to="/home" />
                   
                    <BottomNavigationAction
                        label="Vizita"
                        icon={<LocalHospital />}
                        component={Link}
                        to={{ pathname: "/vizita" }}
                    />
                    <BottomNavigationAction
                        label="Analiza"
                        icon={<Science />}
                        component={Link}
                        to={{ pathname: "/analiza" }}
                    />
                </BottomNavigation>
            </Paper>
        </Box>
    );
}

// Helper function to extract the value from the location
const getValueFromLocation = (location) =>
{
    switch (location.pathname)
    {
        case '/home':
            return 0;
        case '/vizita':
            return 1;
        case '/analiza':
            return 2;
        case '/calendar':
            return 2;
        default:
            return 0;
    }
};
