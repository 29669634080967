import React from 'react';
import TestComponent from './TestComponent'; // Adjust the import path based on your project structure
import { Typography } from '@mui/material';

const TestListComponent = ({ marginTop, testdata, labData, allLabs, clickBool }) =>
{
   
    if (!testdata)
    {
        return <div>Loading...</div>;
    }
    return (

        <div>
            {testdata.map((test, index) => (
                <TestComponent
                    clickBool={clickBool}
                    marginTop={marginTop}
                    key={index}
                    test={test}
                    labData={labData}
                    allLabs={allLabs}
                />
            ))}

        </div>
    );
};

export default TestListComponent;
