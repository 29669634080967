import React, { useContext, useEffect, useState } from 'react';
import
{
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Box,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CardComp from './CardComp';
import CardCover from './CardCover';
import Bottom from './BottomNav';
import DataLoadingSkeleton from './DataLoadingSceleton';
import { useToken } from './TokenContext';
import { useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { CalendarMonthSharp, Science } from '@mui/icons-material';

const Analiza = () =>
{
    const {
        authState: { idPat, token },
        checkTokenExpiration,
    } = useContext(AuthContext);
    const { jwtToken, setToken } = useToken();
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    // Hardcoded lab data
    const [labData, setLabData] = useState(null);

    // Use state to store the selected date
    const [selectedDate, setSelectedDate] = useState(null);

    const fetchLabData = async (idPat, token) =>
    {
        try
        {
            const response = await fetch(`${process.env.REACT_APP_INSALUS_LAB_PATID_ENDPOINT}=${idPat}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok)
            {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const labData = await response.json();
            setLoading(false);
            return labData;
        } catch (error)
        {
          
            throw error;
        }
    };

    useEffect(() =>
    {
        const fetchData = async () =>
        {
            try
            {
                if (!idPat || !token)
                {
                    return;
                }
                await checkTokenExpiration(new Date().getTime());
                const data = await fetchLabData(idPat, token);

                if (data && data.status === 404)
                {
                  
                    setLoading(false);
                    setLabData([]);
                    return;
                }

                setLabData(data);
                setLoading(false);
            } catch (error)
            {
               
                setLoading(false);
            }
        };

        fetchData();
    }, [idPat, token]);

    const handleDateChange = (date) =>
    {
        setSelectedDate((prevDate) => (prevDate === date ? null : date));
    };

    const renderAccordion = () =>
    {
        if (!labData || labData.length === 0)
        {
            return (
                <Typography variant="h6" align="center" color="textSecondary">
                    Nuk ka asnjë analizë për ty.
                </Typography>
            );
        }

        const groupedByDate = {};
        labData.forEach((lab) =>
        {
            const formattedDate = formatDate(lab.date);
            if (!groupedByDate[formattedDate])
            {
                groupedByDate[formattedDate] = [];
            }
            groupedByDate[formattedDate] = [...groupedByDate[formattedDate], lab];
        });

        return Object.entries(groupedByDate).map(([date, labs], index) => (
            <Accordion
                key={index}
                expanded={selectedDate === date}
                onChange={() => handleDateChange(date)}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <CalendarMonthSharp sx={{ color: "#428bf990", marginRight: 2 }} />
                    <Typography color={"#428bf9"}>Analizat e dates:<Typography color={"#ee6c4d"}>{date}</Typography> </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {labs.map((lab, labIndex) => (
                        lab.tests.map((test, testIndex) => (
                            <CardComp
                                clickable={true}
                                key={labIndex * 100 + testIndex}  // Adjust the key as needed
                                cardTittle={test.testDescription}
                                cardTest={lab.requestId}
                                cardDate={formatDate(date)}
                                route={`${lab.requestId}`}
                                tests={test}
                                description={test.testDescription}
                                labData={lab}
                                allLabs={labData}
                                idPat={idPat}
                                token={token}
                            />
                        ))
                    ))}
                </AccordionDetails>
            </Accordion>
        ));

    };

    return (
        <Box flex={4} p={0}>
            {loading ? (
                <DataLoadingSkeleton />
            ) : (
                <>
                    <CardCover
                        title="Analiza"
                        imageUrl="https://medicalmirror.org/wp-content/uploads/2020/09/image-55.jpg"
                        icon={<Science sx={{ width: 40, height: 40, fontSize: 32 }} />}
                    />

                    {renderAccordion()}

                    <Bottom />
                </>
            )}
        </Box>
    );
};

const formatDate = (dateString) =>
{

    const dateObject = new Date(dateString);
    const months = [
        'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
        'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
    ];

    const day = dateObject.getDate();
    const month = months[dateObject.getMonth()];
    const year = dateObject.getFullYear();

    return `${day} ${month} ${year}`.toUpperCase();
};

export default Analiza;
