import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import { theme } from '../theme'
import { Call, Phone, Sms, WhatsApp } from '@mui/icons-material';

export default function NestedList()
{
    const [open, setOpen] = React.useState(true);
    const openURL = (url) =>
    {
        window.location.href = url;
    };

    const handleClick = () =>
    {
        setOpen(!open);
    };
    const phoneNumber = '355682053180';
   
    const openPhoneCall = () =>
    {
        const phoneLink = `tel:${phoneNumber}`;
        window.location.href = phoneLink;
    };
    const openSMS = () =>
    {
        const smsLink = `sms:${phoneNumber}`;
        window.location.href = smsLink;
    };
    const openWhatsapp = () =>
    {
        const smsLink = `https://wa.me/${phoneNumber}`;
        window.location.href = smsLink;
    };
    return (
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: theme.palette.primary.main }}
            component="nav"
            aria-labelledby="nested-list-subheader"

        >
            <ListItemButton onClick={handleClick}>
                <ListItemIcon style={{ color: 'white' }} >
                    <Call />
                </ListItemIcon>
                <ListItemText primary="Kontakto inSalus +355682053180" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }} onClick={openWhatsapp}>
                        <ListItemIcon style={{ color: 'white' }}>
                            <WhatsApp />
                        </ListItemIcon>
                        <ListItemText primary="Dergo nje mesazh ne Whatsapp" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }} onClick={openSMS}>
                        <ListItemIcon style={{ color: 'white' }}>
                            <Sms />
                        </ListItemIcon>
                        <ListItemText primary="Dergo nje SMS" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }} onClick={openPhoneCall}>
                        <ListItemIcon style={{ color: 'white' }}>
                            <Phone />
                        </ListItemIcon>
                        <ListItemText primary="Telefono inSalus" />
                    </ListItemButton>
                </List>
            </Collapse>
        </List>
    );
}
