
import Feed from "./components/Feed";
import { Box, Stack, ThemeProvider, createTheme } from "@mui/material";
import { theme } from './theme.js'
import { TokenProvider } from './components/TokenContext'




function App()
{

  return (


    <ThemeProvider theme={theme}>
      <TokenProvider><Box margin={0} bgcolor={theme.palette.otherColor.main}>
        <Stack direction="row" spacing={2} justifyContent="space-between">


          <Feed />

        </Stack>
      </Box>
      </TokenProvider>
    </ThemeProvider>

  );
}

export default App;
