import React from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { theme } from '../theme';

const CardCover = ({ imageUrl, title, icon, elementName, testDate, testId, tests, onBack }) =>
{
    const cardStyle = {
        paddingTop: 2,
        paddingBottom: 2,
        marginTop: 0, // Adjusted margin to fit as a fixed header
        marginBottom: 3,
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '10px',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        position: 'fixed', // Set to fixed for a fixed header
        top: 0, // Position at the top
        zIndex: 1000, // Set a high z-index to ensure it's above other content
    };

    const backgroundStyle = {
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 0.1,
        zIndex: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '10px',
    };

    const contentStyle = {
        marginTop: '120px', // Adjusted margin to provide space for the fixed header
    };

    return (
        <Box flex={0} padding={0}>
            <Card sx={cardStyle}>
                <div style={backgroundStyle}></div>
                <CardContent sx={{ color: theme.palette.otherColor.main }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item color={theme.palette.otherColor.main} >
                            {icon}
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">{title}</Typography>
                            <Typography color="#efefef" variant="body1">
                                {elementName}
                            </Typography>
                            <Typography color="#efefef" variant="body1">
                                {testDate}
                            </Typography>
                            <Typography color="#efefef" variant="body1">
                                {testId}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <div style={contentStyle}>{/* Content goes here */}</div>
        </Box>
    );
};

export default CardCover;
