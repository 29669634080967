import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Paper, Typography, List, ListItem, ListItemText, Box, Button } from '@mui/material';
import dayjs from 'dayjs';
import CardComp from './CardComp';
import CardCover from './CardCover';
import { LocalHospital } from '@mui/icons-material';

const CustomCalendar = ({ events }) =>
{
    const eventsForDate = events || [];
    const [selectedDate, setSelectedDate] = useState(null);
    const today = dayjs();
    const [showAll, setShowAll] = useState(false);
    const formatDate = (dateString) =>
    {
        const dateObject = new Date(dateString);
        const months = [
            'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
            'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
        ];

        const day = dateObject.getDate();
        const month = months[dateObject.getMonth()];
        const year = dateObject.getFullYear();

        return `${day} ${month} ${year}`.toUpperCase();
    };
    const handleShowAll = () =>
    {
        setSelectedDate(null);
        setShowAll(true);
    };
    const handleDateChange = (date) =>
    {
        setSelectedDate(date);
        setShowAll(false);
    };

    const tileClassName = ({ date }) =>
    {
        const eventsForDate = events || []; // Ensure events is an array or use an empty array

        const isEventDate = eventsForDate.some((event) => dayjs(event.eventDate).isSame(date, 'day'));
        const isPastDate = dayjs(date).isBefore(today, 'day');
        const isUpcomingDate = dayjs(date).isAfter(today, 'day');
        const isToday = dayjs(date).isSame(today, 'day');
        const isEventDone = eventsForDate.some((event) =>
            dayjs(event.eventDate).isSame(date, 'day') && event.eventDone
        );

        if (isToday)
        {
            return 'today-date';
        }

        if (isEventDate && isPastDate)
        {
            if (isEventDone && isPastDate)
            {
                return 'past-event-date-done';
            }
            return 'past-event-date';
        }

        if (isEventDate && isUpcomingDate)
        {
            return 'upcoming-event-date';
        }

        if (isEventDate)
        {
            return 'event-date';
        }

        return '';
    };


    return (
        <Box flex={4} p={0.5} display={"flex"} flexDirection={"column"} justifyContent="center" alignItems="center">
            <style>
                {`
   .react-calendar { 
    width: 380px;
    max-width: 100%;
    background-color: #fff;
    color: #428bf9;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    padding: 0px;
    margin: 0px ;
    border: 0;

   }
   .react-calendar__navigation button {
    color: #428bf9;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
   }
   .react-calendar__navigation button:enabled:hover,
   .react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
   }
   .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
   }
   abbr[title] {
    text-decoration: none;
   }
   /* .react-calendar__month-view__days__day--weekend {
    color: #428bf9;
   } */
   .react-calendar__tile:enabled:hover,
   .react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    color: #428bf9;
    border-radius: 6px;
   }
   .react-calendar__tile--now {
    background: #6f48eb33;
    border-radius: 6px;
    font-weight: bold;
    color: #428bf9;
   }
   .react-calendar__tile--now:enabled:hover,
   .react-calendar__tile--now:enabled:focus {
    background: #6f48eb33;
    border-radius: 6px;
    font-weight: bold;
    color: #6f48eb;
   }
   .react-calendar__tile--hasActive:enabled:hover,
   .react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;
   }
   .react-calendar__tile--active {
    background: #428bf9;
    border-radius: 6px;
    font-weight: bold;
    color: white;
   }
   .react-calendar__tile--active:enabled:hover,
   .react-calendar__tile--active:enabled:focus {
    background: #428bf9;
    color: white;
   }
   .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #f8f8fa;
   }
   .react-calendar__tile--range {
    background: #428bf9;
    color: #6f48eb;
    border-radius: 0;
   }
   .react-calendar__tile--rangeStart {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #428bf9;
    color: white;
   }
   .react-calendar__tile--rangeEnd {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: #428bf9;
    color: white;
   }
   .event-date {
    background-color: #880e4f;
    color: #f8bbd0;
    border-radius: 6px;
  }

  .past-event-date {
    background-color: #F69C9E; /* Red for past event dates */
    color: white;
    border-radius: 6px;
  }

  .past-event-date-done {
    background-color: #428bf999; /* Blue for past done event dates */
    color: white;
    border-radius: 6px;
  }

  .upcoming-event-date {
    background-color: #BCECE6; /* Green for upcoming event dates */
    color: green;
    border-radius: 6px;
  }

  .today-date {
    border: 2px solid blue;
    border-radius: 6px;
  }
  `}
            </style>




            <Calendar
                onChange={handleDateChange}
                value={selectedDate}
                tileClassName={tileClassName}
                calendarType="US"
                className="calendar"
            />
            {/* Legend Section */}
            <div style={{ display: 'flex', marginTop: '10px', maxWidth: '360px' }}>
                <div className="legend-color" style={{ border: '2px solid #F69C9E', borderRadius: '6px' }}></div>
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                    Vizita te humbura
                </Typography>

                <div className="legend-color" style={{ border: '2px solid #428bf9', borderRadius: '6px' }}></div>
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                    Vizita te perfunduara
                </Typography>

                <div className="legend-color" style={{ border: '2px solid #BCECE6', borderRadius: '6px' }}></div>
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                    Vizita te ardhshme
                </Typography>

                <div className="legend-color" style={{ border: '2px solid #6f48eb66', borderRadius: '6px' }}></div>
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                    Dita e sotme
                </Typography>
            </div>
            {/* End Legend Section */}
            <Button variant="contained" onClick={handleShowAll} sx={{ marginTop: 2, width: "90%" }}>
                Te Gjitha
            </Button>
            {selectedDate && (
                <Paper sx={{ marginTop: 4, padding: 1 }}>
                    <Typography variant="h6" className="calendar-typography">
                        Event ne daten {dayjs(selectedDate).format('DD-MMM-YYYY')}
                    </Typography>
                    {eventsForDate.length > 0 ? (
                        <List>
                            {eventsForDate
                                .filter((event) => dayjs(event.eventDate).isSame(selectedDate, 'day'))
                                .map((event, index) => (
                                    <CardComp
                                        key={index}
                                        clickable={false}
                                        cardTittle={event.eventDescription}
                                        cardTest={event.eventDoctor}
                                        cardDate={formatDate(event.eventDate)}
                                    />
                                ))}
                        </List>
                    ) : (
                        <Typography>Asnje event per kete date.</Typography>
                    )}
                </Paper>
            )}

            {showAll && (
                <Paper sx={{ marginTop: 4, padding: 1 }}>
                    <Typography variant="h6" className="calendar-typography">
                        All Events
                    </Typography>
                    {events && events.length > 0 ? (
                        <List>
                            {events.map((event, index) => (
                                <CardComp
                                    clickable={false}
                                    key={index}
                                    cardTittle={event.eventDescription}
                                    cardTest={event.eventDoctor}
                                    cardDate={formatDate(event.eventDate)}
                                />
                            ))}
                        </List>
                    ) : (
                        <Typography>Asnje event i regjistruar.</Typography>
                    )}
                </Paper>
            )}
        </Box>

    );
};

export default CustomCalendar;
