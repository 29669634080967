import React, { useState, useEffect, useRef, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import InSalusLogo from '../sources/inSaluslogo.png';
import MainLogo from '../sources/salusMain.png';
import { Box } from '@mui/material';
import fetchAuthenticationData from './auth';
import { useToken } from './TokenContext';
import { useParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext'





const Authent = ({ onLogin }) =>
{
    const { uuid } = useParams();
    const { jwtToken, setToken } = useToken();
    const [UUid, setUUid] = useState('');

    const [expiresIn, setexpiresIn] = useState('');
    const [auth, setAuth] = useState(false);
    const [SmsCode, setSmsCode] = useState('');
    const [isUUidSubmitted, setUUidSubmitted] = useState(false);
    const [inputs, setInputs] = useState(['', '', '', '', '', '']);
    const navigate = useNavigate();
    const [id, setId] = useState('');
    const [counter, setCounter] = useState(120);
    const textBoxes = useRef(Array.from({ length: inputs.length }, () => React.createRef()));
    const { saveAuthState } = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState('');
    const HandleAuthent = async () =>
    {
        try
        {
            const combinedText = inputs.join('');

            const authCode = combinedText;
            const IdWeb = uuid;
            const data = await fetchAuthenticationData(jwtToken, expiresIn, authCode, IdWeb)

            setToken(data.jwtToken)


            setCounter(120);

            const General = process.env.INSALUS_GENERAL_UUID_ENDPOINT;
            const endpoint1 = `http://in.salus.al:8001/api/general/authcode?authcode=${combinedText}&id_web=${uuid}`;

            // Replace 'YOUR_BEARER_TOKEN' with your actual Bearer token


            const response = await fetch(endpoint1, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${data.jwtToken}`,
                    'Content-Type': 'application/json', // Adjust the content type if necessary
                },
            });

            if (response.ok)
            {

                const responseData = await response.json();
                const token = data.jwtToken
                const idPat = responseData.idPat
                const patName = responseData.patName
                const patSurname = responseData.patSurname
                const typeOfCard = responseData.typeOfCard
                const tokenTime = new Date().getTime();
                saveAuthState({ token, idPat, uuid, tokenTime, patName, patSurname, typeOfCard });

                navigate('/home');
            } else
            {
                const errorResponse = await response.json();

                if (response.status === 404)
                {
                    setErrorMessage('Not Found');
                } else if (response.status === 204)
                {
                    setErrorMessage('No Content');
                } else if (response.status === 400)
                {
                    setErrorMessage(errorResponse.message || 'An error occurred');
                 
                } else
                {
                    // Handle other error status codes
                    setErrorMessage('An unexpected error occurred');
                }
            }


            // Handle the response data as needed



        } catch (error)
        {

        }
    };

    useEffect(() =>
    {
        const fetchData = async () =>
        {
            try
            {
                const response = await fetch(`${process.env.REACT_APP_INSALUS_GENERAL_UUID_ENDPOINT}=${uuid}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

               

                if (response.ok)
                {


                }
                else
                {
                    const errorResponse = await response.json();

                    if (response.status === 404)
                    {
                        setErrorMessage('Not Found');
                    } else if (response.status === 204)
                    {
                        setErrorMessage('No Content');
                    } else if (response.status === 400)
                    {
                        setErrorMessage(errorResponse.message || 'An error occurred');
                       
                    } else
                    {
                        // Handle other error status codes
                        setErrorMessage('An unexpected error occurred');
                    }
                }

            } catch (error)
            {

                // Handle error state or setAuth(false);
            }
        };

        fetchData(); // Call the fetchData function when the component mounts
        const intervalId = setInterval(() =>
        {
            setCounter((prevCounter) => prevCounter - 1);
        }, 1000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [uuid, auth]); // Include dependencies in the dependency array if needed





    const initializeRefs = () =>
    {
        textBoxes.current = Array.from({ length: inputs.length }, (_, index) => textBoxes.current[index] || React.createRef());
    };
    const handleInputChange = (index, event) =>
    {
        const { value } = event.target;
        const newInputs = [...inputs];

        // Handle paste
        if (value.length === 6)
        {
            const pasteArray = value.split('');

            for (let i = 0; i < pasteArray.length; i++)
            {
                newInputs[index + i] = pasteArray[i];

                if (index + i < textBoxes.current.length && index + i < newInputs.length)
                {
                    const currentTextBox = textBoxes.current[index + i];
                    if (currentTextBox && currentTextBox.current)
                    {
                        currentTextBox.current.value = pasteArray[i];
                    }
                }
            }

            setInputs(newInputs);
            return;
        }

        // Handle single character input
        if (value.length === 1)
        {
            newInputs[index] = value;

            setInputs(newInputs);

            if (index < textBoxes.current.length - 1 && index < 5)
            {
                const nextTextBox = textBoxes.current[index + 1];
                if (nextTextBox && nextTextBox.current)
                {
                    nextTextBox.current.focus();
                }
            }
        } else if (value.length === 0 )
        {
            // Handle backspace
            newInputs[index] = '';
            setInputs(newInputs);

            const prevTextBox = textBoxes.current[index - 1];
            if (prevTextBox && prevTextBox.current)
            {
                prevTextBox.current.focus();
            }
        }
    };



    return (
        <Box flex={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width={"100%"}
            paddingTop={2}
            overflow="hidden" // Hide overflow to prevent scrolling 
        >
            <header style={{ width: '100%' }}>
                <div style={{
                    width: '150px',
                    height: '100%',
                    margin: '0 auto',
                    overflow: 'hidden', // Ensure the image is clipped to the container size
                }}>
                    <img
                        src={InSalusLogo}
                        alt="InSalus Logo"
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover', // Adjust how the image is fitted within the container
                        }}
                    />
                </div>
            </header>
            <main style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', flexGrow: '1', width: '90%', padding: '0px', boxSizing: 'border-box', margin: '0 auto',marginTop:'50px', textAlign: 'center' }}>
                <div style={{
                    width: '370px',
                    height: '100%',
                    flexShrink: '0',
                    marginBottom: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden', // Ensure the image is clipped to the container size
                }}>
                    <img
                        src={MainLogo}
                        alt="Main Logo"
                        style={{
                            width: '90%',
                            height: '100%',
                            objectFit: 'contain', // Adjust how the image is fitted within the container
                        }}
                    />
                </div>
                <div style={{ width: '90%', height: '84px', flexShrink: '0', overflow: 'show', color: '#2B6CEA', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '45px', fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal' }}>Autentifikim</div>
                <div style={{ color: 'rgba(46, 60, 140, 0.90)', fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif', fontSize: '12px', fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', marginBottom: '20px' }}>Një kod me 6 shifra ju është dërguar me SMS në telefon</div>
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '20px', gap: '10px', maxWidth: '400px' }}>
                    {inputs.map((input, index) => (
                        <input
                            key={index}
                            ref={textBoxes.current[index]}
                            type="text"
                            style={{ width: '45px', height: '45px', padding: '10px', boxSizing: 'border-box', border: '1px solid #CEDEFF', borderRadius: '5px', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)', textAlign: 'center', borderColor: '#ccc', fontSize: '16px' }}

                            value={input}
                            onChange={(event) => handleInputChange(index, event)}
                        />
                    ))}
                </div>

                <div style={{ color: 'rgba(46, 60, 140, 0.90)', fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif', fontSize: '12px', fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', marginBottom: '20px' }}>
                    Nuk iu ka ardhur kodi akoma ? <br></br> {counter > 0 ? `${counter < 10 ? `0${counter}` : counter}` : 'Rifreskoni faqen per kodin e ri !'}
                </div>
                <div style={{ color: 'red', fontSize: '14px', marginBottom: '10px' }}>
                    {errorMessage && errorMessage}
                </div>
                <button style={{ width: '100%', maxWidth: '241px', height: '41px', marginTop: '20px', borderRadius: '10px', background: '#2B6CEA', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif', fontSize: '16px', fontWeight: '700', lineHeight: 'normal', borderColor: '#CEDEFF' }} onClick={HandleAuthent}>Vazhdo</button>
            </main>
        </Box>
    );
};

export default Authent;
