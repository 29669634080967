import React, { useState } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ClickableCard = ({ imageUrl, title, icon, theme, route, idPat ,token }) =>
{
    const navigate = useNavigate();
    const [isClicked, setIsClicked] = useState(false);

    const handleClick = () =>
    {
        // Navigate to the specified route when the card is clicked

        // Add a short delay before resetting the click state to allow the click animation to complete
        setTimeout(() => setIsClicked(false), 1000);

        navigate(route, {
            state: {
                idPat: idPat,
                token : token
            },
        });
    };

    const cardStyle = {
        paddingTop: 2,
        paddingBottom: 2,
        marginTop: 3,
        marginBottom: 3,
        width: '100%',
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '10px',
        position: 'relative',
        cursor: 'pointer', // Add pointer cursor to indicate clickability
        transition: 'transform 0.5s ease-out', // Add transition for the scale effect
        transform: isClicked ? 'scale(0.95)' : 'scale(1)', // Scale down on click
    };

    const backgroundStyle = {
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 0.1,
        zIndex: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '10px',
    };

    const contentStyle = {
        color: theme.palette.otherColor.main,
        position: 'relative',
        zIndex: 1,
    };

    return (
        <Card sx={cardStyle} onClick={handleClick}>
            <div style={backgroundStyle}></div>
            <CardContent sx={contentStyle}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        {icon}
                    </Grid>
                    <Grid item>
                        <Typography variant="h5">{title}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ClickableCard;
