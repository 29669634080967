import * as React from 'react';
import { Box } from '@mui/material';
import EventCalendar from './EventCalendar';
import { CalendarIcon } from '@mui/x-date-pickers';
import CardCover from './CardCover';
import Bottom from './BottomNav';
import { useLocation } from 'react-router-dom';


const Calendar = () =>
{
    const location = useLocation();
    const { eventData } = location.state || {};



    return (
        <Box >
            <CardCover
                imageUrl="https://www.usnews.com/object/image/00000184-5d22-d253-a784-dda799cf0000/gettyimages-1390026192.jpg?update-time=1668009859280&size=responsive970"
                title="Kalendari inSalus"
                icon={<CalendarIcon sx={{ width: 40, height: 40, fontSize: 32 }} />}
            />
            <EventCalendar events={eventData} />
            <Bottom />
        </Box>
    );

};

export default Calendar;