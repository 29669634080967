
import React, { useState } from 'react'
import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material'
import { theme } from '../theme'
import { ArrowForward, ArrowForwardIos } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
const CardComp = ({ arrowicon, clickable, cardTittle, cardTest, rtfContent, cardDate, route, tests, description, labData, allLabs, idPat, token }) =>
{
  const [day, month, year] = cardDate.split(' ');
  const navigate = useNavigate();
  const [isClicked, setIsClicked] = useState(false);
  
  const handleClick = () =>
  {
    if (clickable == true ){
      navigate(route, {
        state: {
          elementName: cardTittle,
          testData: tests,
          testDate: cardDate,
          testId: cardTest,// Use cardTittle instead of elementName
          labData: labData,
          allLabs: allLabs,
          idPat: idPat,
          token: token,
          rtfContent: rtfContent

        },
      });

    }

  };
  

  return (
    <Card onClick={handleClick} sx={{ position: 'relative', marginTop: 1.5, marginBottom: 1.5, marginLeft: 1, marginRight: 1, paddingTop: 0, borderRadius: '10px' }}>

      <CardContent sx={{ color: theme.palette.primary.main }}>
        <Grid container spacing={1} alignItems="center" justifyContent="space-between">
          <Grid item marginTop={0}>
            <Typography variant="h5" style={{ lineHeight: '1', textAlign: 'center' }}>
              <strong>{day}</strong>
            </Typography>
            <Typography variant="body2" style={{ lineHeight: '1', textAlign: 'center' }}>
              <strong>{month}</strong>
            </Typography >
            <Typography style={{ color: 'GrayText', fontSize: 12, marginTop: 4, lineHeight: '1', textAlign: 'center' }}>
              {year}
            </Typography >
          </Grid>
          <Grid item xs={9} md={6} >
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              margin={0}
            >
              {cardTittle}
            </Typography>
            <Typography variant="button" color="#ee6c4d">
              <strong>{cardTest}</strong>
            </Typography>
          </Grid>
          <Grid item>
            <ArrowForwardIos />
          </Grid>
        </Grid>

      </CardContent>

    </Card>
  )
}
export default CardComp