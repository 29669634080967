import React, { createContext, useContext, useState } from 'react';

const TokenContext = createContext();

export const TokenProvider = ({ children }) =>
{
    const [jwtToken, setJwtToken] = useState(null);

    const setToken = (token) =>
    {
        setJwtToken(token);
       
    };

    return (
        <TokenContext.Provider value={{ jwtToken, setToken }}>
            {children}
        </TokenContext.Provider>
    );
};

export const useToken = () =>
{
    const context = useContext(TokenContext);
    if (!context)
    {
        throw new Error('useToken must be used within a TokenProvider');
    }
    return context;
};
