import React from 'react';
import { Box, Card, CardContent, Grid, Typography, Skeleton } from '@mui/material';

const CardCoverSceleton = () =>
{
    const backgroundStyle = {
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundColor: 'gray', // Set the background color for the skeleton
        borderRadius: '15px',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
    };

    return (
        <Box flex={0} padding={0}>
            <Card sx={{ position: 'relative', padding: 0, marginTop: 0, marginBottom: 3, width: '100%', borderRadius: '15px', borderTopRightRadius: 0, borderTopLeftRadius: 0 }}>
                <div style={backgroundStyle}></div>
                <CardContent sx={{ color: 'gray' }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Skeleton variant="circular" width={40} height={40} />
                        </Grid>
                        <Grid item>
                            <Typography variant="h4">
                                <Skeleton variant="text" width={100} />
                            </Typography>
                            <Typography color="#efefef" variant="body1">
                                <Skeleton variant="text" width={150} />
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Box>
    );
};

export default CardCoverSceleton;
