import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';
import { Box, Button, IconButton } from '@mui/material';
import CardCover from './CardCover';
import TestListComponent from './TestListComponent';
import Bottom from './BottomNav';
import { AuthContext } from './AuthContext';

const Elements = () =>
{
    const navigate = useNavigate();
    const location = useLocation();
    const { elementName, testData, labData, allLabs   } = location.state || {};
    const { testDate, testId } = location.state || {};
    const { authState: { idPat, token } ,  checkTokenExpiration} = useContext(AuthContext);


    const onBack = () =>
    {
      

        navigate(-1); // Navigates back to the previous page

    };
    useEffect(()=> {
        checkTokenExpiration(new Date().getTime());
    });

    return (
        <Box flex={4} p={0}>
            <CardCover
                testDate={testDate}
                testId={testId}
                elementName={elementName}
                title="Analiza"
                imageUrl="https://medicalmirror.org/wp-content/uploads/2020/09/image-55.jpg"
                icon={<IconButton onClick={onBack} sx={{ color: 'white' }} ><ArrowBackIos /></IconButton>}
            />
            <TestListComponent
                clickBool={true}
                marginTop={25}
                testdata={[testData]}
                labData={labData}
                allLabs={allLabs}
            />

            <Bottom idPat={idPat} token={token} />
        </Box>
    );
};

export default Elements;
