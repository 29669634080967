
const fetchAuthenticationData = async (setJwtToken, setExpiresin, authCode, IdWeb) =>
{
    try
    {
        // Indicate that data is being fetched
        // setLoading(true);

        const requestBody = {
            IdWeb: IdWeb,
            AuthCode: authCode,

        };

        const response = await fetch(process.env.REACT_APP_INSALUS_AUTH_ENDPOINT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        if (response.ok)
        {
            const data = await response.json();
            console.log(IdWeb)
            return { jwtToken: data.jwtToken, expiresIn: data.expiresIn };

        } else
        {
            console.error('Error fetching authentication data. Server returned:', response.status, response.statusText);
            console.log(IdWeb)
            // Handle error state, show error message, or setAuth(false);
        }
    } catch (error)
    {
        console.error('Error fetching authentication data:', error);
        // Handle error state, show error message, or setAuth(false);
    } finally
    {
        // Clear loading state
        // setLoading(false);
    }
};

export default fetchAuthenticationData;
