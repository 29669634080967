// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();


export const AuthProvider = ({ children }) =>
{
  const navigate = useNavigate();
  const [authState, setAuthState] = useState({
    token: null,
    idPat: null,
    uuid: null,
    tokenTime: null,
    patName: null,
    patSurname: null,
    typeOfCard: null
  });

  useEffect(() =>
  {
    // Load authentication state from storage on component mount
    const storedAuthState = JSON.parse(localStorage.getItem('authState'));
    if (storedAuthState)
    {
      setAuthState(storedAuthState);
    }
  }, []);
  const checkTokenExpiration = (nowTime) =>
  {
    const { tokenTime, uuid, token } = authState;

    if (tokenTime && nowTime - tokenTime > 20 * 60 * 1000)
    { // 20 minutes in milliseconds
      // Token has expired
      alert("Koha e perdorimit skadoi");

      navigate(`/${uuid}`)
      clearAuthState();
    } else if (!token)
    {
      // If token is null, navigate to '/uuid'
      alert("Ju lutem skanoni karten tuaj");
      navigate(`/${uuid}`)
    }
  };

  const saveAuthState = (newAuthState) =>
  {
    // Save authentication state to storage
    localStorage.setItem('authState', JSON.stringify(newAuthState));
    setAuthState(newAuthState);
  };

  const clearAuthState = () =>
  {
    // Clear authentication state from storage
    localStorage.removeItem('authState');
    setAuthState({
      token: null,
      patId: null,
      uuid: null,
      tokenTime: null,
      patienName: null,
      patSurname: null,
      typeOfCard: null
    });
  };

  return (
    <AuthContext.Provider value={{ authState, saveAuthState, clearAuthState, checkTokenExpiration }}>
      {children}
    </AuthContext.Provider>
  );
};
