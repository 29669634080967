import React from 'react';
import { Grid, Paper, Typography, Box, Tooltip } from '@mui/material';
import { theme } from '../theme';
import { useNavigate } from 'react-router-dom'
import { click } from '@testing-library/user-event/dist/click';
import { CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts';

const TestComponent = ({ marginTop, clickBool, test, labData, allLabs }) =>
{


    const navigate = useNavigate();
    if (!test || !test.testDescription || !test.elements || !Array.isArray(test.elements))
    {
        return null;
    }
    const route = null

    const handleClick = (element) =>
    {
        if (clickBool === true)
        {
            navigate(`/analiza/${labData.requestId}/element/${element.elementId}`, {
                state: {
                    rangeMin: element.rangeMin,
                    rangeMax: element.rangeMax,
                    result: element.result,
                    unit: element.unit,
                    labData: labData,
                    element: element.elementName,
                    elementId: element.elementId,
                    allLabs: allLabs
                },
            });
            // Use cardTittle instead of elementName

        };



    };


    return (
        <Box sx={{ marginTop: marginTop }}>
            <Grid container spacing={2}>
                <Typography sx={{ marginLeft: 3, paddingTop: 3, color: theme.palette.primary.main }} variant="h6">{test.testDescription}</Typography>
                {test.elements.map((element, index) => (
                    <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                        <Paper onClick={() => handleClick(element)} elevation={3} style={{ padding: '16px', height: '100%' }}>
                            <Typography sx={{ color: theme.palette.primary.main }} variant="h6">{element.elementName}</Typography>
                            <Typography >Result: {element.result}  {element.unit}</Typography>
                            <Typography marginBottom={6}>
                                Range: {`${element.rangeMin}-${element.rangeMax}`}
                            </Typography>

                            {renderProgressBar(element.result, element.rangeMin, element.rangeMax)}
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

const renderProgressBar = (result, rangeMin, rangeMax) =>
{
    const numericResult = parseFloat(result);
    result = parseFloat(result);

    // Check if the range contains '-'


    if (!isNaN(numericResult) && !isNaN(parseFloat(rangeMin)) && !isNaN(parseFloat(rangeMax)))
    {

        const addedSegment = (parseFloat(rangeMax) - rangeMin);

        const lowerLimit = rangeMin - (addedSegment * 0.2) / 0.6;
        const upperLimit = parseFloat(rangeMax) + (addedSegment * 0.2) / 0.6;

        let positionPercentage;
        let positionLow;
        let positionHigh;
        const totalRange = upperLimit - lowerLimit;
        positionLow = ((0 - lowerLimit) / totalRange) * 100;
        positionHigh = ((0 - upperLimit) / totalRange) * 100;
        if (numericResult < lowerLimit)
        {
            // Below the lower limit
            positionPercentage = 0;
        } else if (numericResult > upperLimit)
        {
            // Above the upper limit
            positionPercentage = 100;
        } else
        {
            const totalRange = upperLimit - lowerLimit;

            positionPercentage = ((numericResult - lowerLimit) / totalRange) * 100;


        }

        return (
            <Box marginTop={3} marginBottom={7} position="relative">
                {/* Low limit */}
                <Box
                    sx={{
                        height: 10,
                        width: '20%',
                        backgroundColor: '#FF6962',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 0,
                    }}
                />
                {/* Normal range */}
                <Box
                    sx={{
                        height: 10,
                        width: '60%',
                        backgroundColor: '#7ABD91',
                        position: 'absolute',
                        top: 0,
                        left: '20%',
                        zIndex: 0,

                    }}

                />
                <Typography
                    sx={{
                        height: 10,

                        position: 'absolute',
                        top: '7px',
                        left: '20%', // Adjusted left to center within the Box
                        zIndex: 0,
                        color: theme.palette.primary.main

                    }}
                >
                    {rangeMin}
                </Typography>
                <Typography
                    sx={{
                        height: 10,

                        position: 'absolute',
                        top: '7px',
                        left: '80%', // Adjusted left to center within the Box
                        zIndex: 0,
                        color: theme.palette.primary.main

                    }}
                >
                    {rangeMax}
                </Typography>
                {/* High limit */}
                <Box
                    sx={{
                        height: 10,
                        width: '20%',
                        backgroundColor: '#FF6962',
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        zIndex: 0,
                    }}
                />
                {renderMarker(numericResult, positionPercentage)}
            </Box>
        );
    }

    return null;
};

const renderMarker = (numericResult, positionPercentage) =>
{
    const markerPosition = `calc(${positionPercentage}% )`;

    return (

        <Box position="absolute" top={-40} fontSize={12} left={markerPosition}>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 0,
                    backgroundColor: '#1976D2',
                    padding: '8px',
                    borderRadius: '8px',
                    color: 'white',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
                }}
            >
                {`${numericResult.toFixed(2)}`}
            </Box>
            <Box
                sx={{
                    marginTop: '27px',
                    width: 0,
                    height: 0,
                    borderLeft: '6px solid transparent',
                    borderRight: '6px solid transparent',
                    borderTop: '10px solid #1976D2',
                    position: 'absolute',
                    transform: 'translateX(-50%)',
                    zIndex: 0,
                }}
            />

        </Box>
    );


};



export default TestComponent;
