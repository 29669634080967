import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalHospital } from '@mui/icons-material';
import CardComp from './CardComp';
import CardCover from './CardCover';
import DataLoadingSkeleton from './DataLoadingSceleton';
import Bottom from './BottomNav';
import { AuthContext } from './AuthContext';

const Vizita = () =>
{
  const { authState: { idPat, token }, checkTokenExpiration } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [vizitaData, setVizitaData] = useState([]);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const formatDate = (dateString) =>
  {
    const dateObject = new Date(dateString);
    const months = [
      'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN',
      'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
    ];

    const day = dateObject.getDate();
    const month = months[dateObject.getMonth()];
    const year = dateObject.getFullYear();

    return `${day} ${month} ${year}`.toUpperCase();
  };
  useEffect(() =>
  {

    const fetchVizitaData = async () =>
    {
      try
      {
        if (!idPat)
        {
          // idPat is not available yet, return or handle accordingly
          return;
        }
        if (!token)
        {
          // idPat is not available yet, return or handle accordingly
          return;
        }
        await checkTokenExpiration(new Date().getTime());
        const response = await fetch(`${process.env.REACT_APP_INSALUS_REFERT_PATID_ENDPOINT}=${idPat}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok)
        {
          if (response.status === 404)
          {
            // Handle 404 response
            setError('Nuk ka asnjë refert.');
          } else
          {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        } else
        {

          const data = await response.json();

          setVizitaData(data);
         

        }

        setLoading(false);
      } catch (error)
      {
       
        setError('Gabim gjatë shkarkimit të të dhënave.');
        setLoading(false);
      }
    };

    fetchVizitaData();
  }, [idPat, token]);

  return (
    <Box flex={4} p={0}>
      {loading ? (
        <DataLoadingSkeleton />
      ) : error ? (
        <>
          <CardCover
            imageUrl="https://www.usnews.com/object/image/00000184-5d22-d253-a784-dda799cf0000/gettyimages-1390026192.jpg?update-time=1668009859280&size=responsive970"
            title="Vizita"
            icon={<LocalHospital sx={{ width: 40, height: 40, fontSize: 32 }} />}
          />
          <Typography variant="h6" align="center" color="textSecondary">
            {error}
          </Typography>
          <Bottom />
        </>
      ) : (
        <>
          <CardCover
            imageUrl="https://www.usnews.com/object/image/00000184-5d22-d253-a784-dda799cf0000/gettyimages-1390026192.jpg?update-time=1668009859280&size=responsive970"
            title="Vizita"
            icon={<LocalHospital sx={{ width: 40, height: 40, fontSize: 32 }} />}
          />

          {vizitaData.map((vizita, index) => (
            <CardComp
              clickable={true}
              key={index}
              cardTittle={vizita.serviceDescription}
              cardTest={vizita.requestID}
              route={vizita.requestID}
              cardDate={formatDate(vizita.date)}
              rtfContent={vizita.refertDescription}
            />
          ))}

          <Bottom />
        </>
      )}
    </Box>
  );

};

export default Vizita;
